var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1f812b404137917a440cb68ab9d916923f9be99f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { NODE_ENV, SENTRY_DSN, VERCEL_ENV, VERCEL_GIT_COMMIT_SHA } from './src/constants/envVariables';

import { init } from '@sentry/nextjs';

if (SENTRY_DSN) {
  init({
    dsn: SENTRY_DSN,
    enabled: NODE_ENV !== 'development',
    environment: VERCEL_ENV,
    release: VERCEL_GIT_COMMIT_SHA,
    integrations: [],
  });
}
